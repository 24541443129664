import React from 'react'
import "./App.css" 

const cir = () => {
  return (
    <div className=' d-flex col-lg-12 col-sm-12 ' >
       <div className=' col-3 col-sm-6 ' >
       <img className='okoko' src='https://ekaksharbuildtech.com/circleSanjeev-Sir.webp' alt='ok' />
       </div>
       <div className=' col-3 col-sm-6 ' >
       <img className='okoko' src='https://ekaksharbuildtech.com/circleSanjeev-Sir.webp' alt='ok' />
       </div>
       <div className=' col-3 col-sm-6 ' >
       <img className='okoko' src='https://ekaksharbuildtech.com/circleSanjeev-Sir.webp' alt='ok' />
       </div>
       <div className=' col-3 col-sm-6 ' >
       <img className='okoko' src='https://ekaksharbuildtech.com/circleSanjeev-Sir.webp' alt='ok' />
       </div>

    </div>
  )
}

export default cir